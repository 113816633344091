import React, {useState} from "react";
import stairs from "./assets/img/stairman.png"
import coin from "./assets/img/CultCoinSpin.gif"
//require('dotenv').config();


const GenContainer = () => {
  const [account, setAccount ] = useState([]);
  const [inputValue, setInputValue] = useState("");
  let [inputValue2, setInputValue2] = useState("");
  const isConnected = account && account.length > 0;
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [isCircleGreen, setIsCircleGreen] = useState(false); // Add state for circle color
  const [data, setData] = useState();
  //dotenv.config();


  async function connectAccount2() {
    const tweet = document.querySelector('.tweet');

    if (isRequestPending) {
      console.log("Sign-in request is already pending.");
      return;
    }

    setIsRequestPending(true);

    try {
      if (window.ethereum) {
        const account = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        if (account.length > 0) {
          setAccount(account);
          setIsCircleGreen(true); // Set the circle color to green
          console.log("Account value:", account[0]);

          try {

            const data = {
              wallet: account[0],
              "Connected at": new Date(),
            };

            const response = await fetch("https://sheet.best/api/sheets/f98cdb15-9655-40e0-8bd0-b1b64956dcb0", {
              method: "POST",
              mode: "cors",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            })
              .then((r) => r.json())
              .then((data) => {
                // The response comes here
                console.log(data);
              })
              .catch((error) => {
                // Errors are reported there
                console.log(error);
              });
          
            console.log("API response status:", response.status);

            if (response.ok) {
              console.log("Wallet address written to Google Sheet successfully!");
            } else {
              console.log("Failed to write the wallet address to Google Sheet.");
            }
          } catch (error) {
            console.log("Error connecting to the Sheet.Best API:", error);
          }

          console.log("Wallet address:", account[0]); // Log the wallet address
          //tweet.classList.add('active')
        } else {
          console.log("No accounts found.");
        }
      } else {
        console.log("Ethereum not available.");
      }
    } catch (error) {
      console.log("Error connecting account:", error);
    }

    setIsRequestPending(false);
  }

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

  function handleInputChange2(event) {
   inputValue2 = event.target.value;
   setInputValue2(inputValue2)
  }

  async function walletSubmit() {
   const walletInput = document.querySelector('.inputField')
    if (inputValue2.match('0x')) {
    try {

      const data = {
        wallet: inputValue2,
        "Connected at": new Date(),
      };

      const response = await fetch("https://sheet.best/api/sheets/f98cdb15-9655-40e0-8bd0-b1b64956dcb0", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((r) => r.json())
        .then((data) => {
          // The response comes here
          console.log(data);
        })
        .catch((error) => {
          // Errors are reported there
          console.log(error);
        });
    
      console.log("API response status:", response.status);

      if (response.ok) {
        console.log("Wallet address written to Google Sheet successfully!");
      } else {
        console.log("Failed to write the wallet address to Google Sheet.");
      }
    } catch (error) {
      console.log("Error connecting to the Sheet.Best API:", error);
    }
    walletInput.classList.remove('active');
    console.log('Address listed is ' + inputValue2)
  } else {
    console.log('inproper wallet address')
  }
  }

  function passwordClick() {
    const enterPagebtn = document.querySelector('.noPW');
    const needPW = document.querySelector('.pwbtn');
    const connectbtn = document.querySelector('.Wallet-Connect');
    const walletConnect2 = document.querySelector('.WC2');
    const inputPass = document.querySelector('.inputField')
    const p = document.querySelector('.p');
    const artCover = document.querySelector('.artCover');
    const tweet = document.querySelector('.tweet');
    const h1 = document.querySelector('.h1');
    const genNav = document.querySelector('.genNavBar');
    const genContainerBtn = document.querySelector('.gencontainerbtn');
    const walletInput = document.querySelector('.walletInput');
    const walletbtn = document.getElementById('walletbtn')
    const passwordInput= document.querySelector('.passwordInput');
    const passbtn = document.getElementById('passbtn');


    enterPagebtn.addEventListener('click', ()=> {
      inputPass.classList.add('active');
      h1.classList.add('active');
      enterPagebtn.classList.remove('active');
    });
    if (inputValue.toLowerCase() === "alpha") {
      // Run your desired function here
      console.log("Password entered!");
      needPW.classList.remove('active');
      connectbtn.classList.add('active');
      enterPagebtn.classList.remove('active');
      //inputPass.classList.remove('active');
      p.classList.remove('active');
      artCover.classList.remove('active');
      tweet.classList.add('active');
      h1.classList.remove('active');
      genNav.classList.add('active');
      genContainerBtn.classList.add('active');
      walletConnect2.classList.add('active');
      passwordInput.classList.remove('active');
      passbtn.classList.remove('active');
      walletInput.classList.add('active');
      walletbtn.classList.add('active');
    } else {
      console.log("Incorrect password!");
    }
  }

  const imageTweet = "/assets/img/Skull_Twitter.png";

  const imageUrl = `https://twitter.com/intent/tweet?url=https%3A%2F%2Fskullcoineth.com%2F&via=skullcoineth&text=Just%20submitted%20my%20wallet%20for%20chance%20at%20pre%20sale%20access%20on%20the%20site.%20Face%20your%20final%20%23fate.%20💀☠%20&media=${encodeURIComponent(imageTweet)}`


  return (
    <div id="genContainer" className="genContainer">
      <img className="genIMG" src={stairs} alt="" />
      <div className="artCover active"></div>
      <div className="gentitleCon">
      <div className="inputField">
        <input type="text" className="walletInput" placeholder="Enter Wallet Address" value={inputValue2} onChange={handleInputChange2}/>
        <input type="text" className="passwordInput active" placeholder="Password" value={inputValue} onChange={handleInputChange}/>
        <button id="passbtn" className="active" onClick={passwordClick}>Enter</button>
        <button id="walletbtn" onClick={walletSubmit}>Enter</button>
      </div> 
      <h1 className="h1">$SKULL</h1>
      <button id="enterPageBtn" className="noPW active" onClick={passwordClick}>ENTER PASSWORD</button>

      <a href={imageUrl} data-size="large"><button className="tweet">TWEET</button></a>
      <div className="gencontainerbtn">
              {isConnected ? (
                <div className="Wallet-Connect WC2" id="Wallet-Connect">
                  <button>Connected</button>
                </div>
              ) : (
                <div className="Wallet-Connect WC2" id="Wallet-Connect" >
                  {isRequestPending ? (
                    <button disabled>Pending...</button>
                  ) : (
                    <button onClick={connectAccount2}>Connect Wallet</button>
                  )}
               </div>
            )}
      {isConnected ? (
        <div className={`yellowCircle ${isCircleGreen ? 'green' : ''}`} />
      ) : (
        <div className="yellowCircle" />
      )}
      </div>
      </div>
      <p className="p active">
        REMEMBER THE LAST COIN YOU SAID YOU’D BUY ON THE DIP? REMEMBER WHEN IT
        50X’D AND YOU NEVER ENTERED? WILL YOU MAKE THE SAME MISTAKE ANON? $SKULL
        IS BUILT IN MEMORY OF THOSE WHO KEPT WAITING FOR THE DIP THAT NEVER
        CAME. OUR LOST BRETHREN SEETHING AT THEIR COMPUTER SCREEN AS THEIR
        FAVORITE COIN 100X’D IN FRONT OF THEIR FACE. THE CHOICE IS SIMPLE. BUY
        $SKULL NOW OR FOREVER HOLD YOUR PEACE. IN HEAVEN THERE ARE $SKULL
        HOLDERS AND YOU KNOW WHERE NON-HOLDERS GO. JEETS BANISHED FOREVER. TRUE
        SKULLIES BUY AND KEEP ON BUYING.
        <br />
        <br />
         YOU MAY ASK: BUT WHY IS THERE NO DIP?
        SIMPLE YET GENIUS TOKENOMICS IS YOUR ANSWER. $SKULL IS A SOCIAL THEORY
        COIN BASED ON KNOWING THAT TODAYS PRICE IS NOT TOMORROWS PRICE. WITH
        CONCENTRATED LIQUIDITY WHICH IS CONTINUALLY FED BY VOLUME, THE PRICE
        FLOOR CONTINUES TO MOVE UP. EARLY HOLDERS BECOME THE FIRST WINNERS, BUT
        LATER BUYERS ARE REWARDED WITH STABLE APPRECIATION BACKED BY
        CONCENTRATED V3 LIQUIDITY. NO PAPER HANDED JEETS CAN BREAK OUR
        IMPENETRABLE BID WALL. AS THE LIQUIDITY GROWS, SO WILL THE PRICE FLOOR.
        RESPONSIBLE YET TIMELY ADJUSTMENTS ALLOW FOR A BEAUTIFUL CHART OF GREEN
        ALONG WITH PROSPEROUS HOLDERS.
      </p>
      <div className="links">
        <a href="https://twitter.com/Skullcoineth">TWITTER</a>
        <a href="https://t.me/+j56baQgkfN40ZDNh">TELEGRAM</a>
        <a href="#">CONTRACT</a>
        <a href="#">DEXTOOLS</a>
        <a href="#">DEXSCREENER</a>
      </div>
    </div>
  );
}

export default GenContainer;
