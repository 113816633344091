import React, { useRef, useState, useEffect } from "react";
import Landing from "./Landing";
import GenContainer from "./GenContent";
import BG from "./assets/img/swing.png"
import Divider from "./Divider";
import c from "./assets/img/SKULL_Coin.png";

const PanContainer = ({ accounts, setAccounts }) => {

  const isConnected = accounts && accounts.length > 0;
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [isCircleGreen, setIsCircleGreen] = useState(false); // Add state for circle color


  async function connectAccount() {
    const tweet = document.querySelector('.tweet');

    if (isRequestPending) {
      console.log("Sign-in request is already pending.");
      return;
    }

    setIsRequestPending(true);

    try {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        if (accounts.length > 0) {
          setAccounts(accounts);
          setIsCircleGreen(true); // Set the circle color to green
          console.log("Account value:", accounts[0]);

          try {

            const data = {
              wallet: accounts[0],
              "Connected at": new Date(),
            };

            const response = await fetch("https://sheet.best/api/sheets/f98cdb15-9655-40e0-8bd0-b1b64956dcb0", {
              method: "POST",
              mode: "cors",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            })
              .then((r) => r.json())
              .then((data) => {
                // The response comes here
                console.log(data);
              })
              .catch((error) => {
                // Errors are reported there
                console.log(error);
              });
          
            console.log("API response status:", response.status);

            if (response.ok) {
              console.log("Wallet address written to Google Sheet successfully!");
            } else {
              console.log("Failed to write the wallet address to Google Sheet.");
            }
          } catch (error) {
            console.log("Error connecting to the Sheet.Best API:", error);
          }

          console.log("Wallet address:", accounts[0]); // Log the wallet address
          //tweet.classList.add('active');

        } else {
          console.log("No accounts found.");
        }
      } else {
        console.log("Ethereum not available.");
      }
    } catch (error) {
      console.log("Error connecting account:", error);
    }

    setIsRequestPending(false);
  }

    useEffect(() => {
        const timeout = setTimeout(() => {
          const fade = document.querySelector(".fadeTemp");
          if (fade) {
            fade.classList.add("fadeZin");
          }
        }, 2000);
    
        return () => clearTimeout(timeout);
      }, []);

    return(
        <div className="panContainer">
          <div className="genNavBar">
              <div className="navLogo">
                <img src={c} alt="" />
              </div>
              <div className="genNavBarbtn">
              <button className="pwbtn active" >ENTER PW TO CONNECT</button>
              {isConnected ? (
                <div className="Wallet-Connect" id="Wallet-Connect">
                  <button>Connected</button>
                </div>
              ) : (
                <div className="Wallet-Connect" id="Wallet-Connect" >
                  {isRequestPending ? (
                    <button disabled>Pending...</button>
                  ) : (
                    <button onClick={connectAccount}>Connect Wallet</button>
                  )}
               </div>
            )}
            {isConnected ? (
              <div className={`yellowCircle ${isCircleGreen ? 'green' : ''}`} />
            ) : (
              <div className="yellowCircle" />
            )} 
            </div>
            </div>
            <div className="fadeTemp"></div>
            <img id="mainBG" className="" src={BG} alt="" />
            <Landing />
            <Divider />
            <GenContainer />
        </div>
    );
}

export default PanContainer;