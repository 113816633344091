import React from "react";

function Landing() {

    
    function enterPageClick() {
        const enterBtn = document.getElementById('enterPageBtn');
        const LandingPage = document.getElementById('landing');
        const genConPage = document.getElementById('genContainer');
        const landingIMG = document.getElementById('mainBG');
        const divider = document.getElementById('divider');
        enterBtn.addEventListener('click', ()=> {
            LandingPage.classList.add('active');
            genConPage.classList.add('active');
            landingIMG.classList.add('active');
            divider.classList.add('active');
        })
    }

return(
    <div id="landing" className="landing">
        <div className="titleCon">
            <button id="enterPageBtn" onClick={enterPageClick}>PASSWORD HERE</button>
        </div>
    </div>
);
};

export default Landing;