import './App.scss';
import { useEffect, useState } from 'react';
//import {Route, Routes} from 'react-router-dom';
import PanContainer from './components/PanContainer';
import Loading from './components/Loading';
import Skull from './components/skull.jsx';

function App() {

  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState([]);
  const [isCircleGreen, setIsCircleGreen] = useState(false); // Add state for circle color

  useEffect(() => {
    setTimeout(() => setLoading(false), 4000)
  }, [])


  return (
  <>
    {loading === false ? (
    <div className="App">
      <PanContainer accounts={accounts} account={account} setAccounts={setAccounts} setAccount={setAccount} isCircleGreen={isCircleGreen} />
    </div>
    ) : (
      <Loading />
    )}
    </>
  );
}


export default App;
