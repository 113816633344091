import React from "react";
import loadingBG from "./assets/img/swing.png";
import skullicon from "./assets/img/SKULL_Coin.png";
import { useEffect, useState } from "react";

const Loading = () => {
    
    const [filled, setFilled] = useState(0);
    const [isRunning, setIsRunning] = useState(true);


    useEffect(()=> {
        if(filled < 100 && isRunning)
        setTimeout(()=> setFilled(prev => prev += 2), 101)
    }, [filled, isRunning])


    return(
        <div className="loading">
            <img id="loadingBGIMG" src={loadingBG} alt="" />
            <div className="loadingContent">
                <div className="loadingBarCon">
                    <img id="skullCoin" src={skullicon} alt="" />
                    <p>$SKULL</p>
                    {/*<p id="progPercentage">{ filled }%</p>*/}
                    <div className="loadingBar">                
                        <div className="progressBarCon">
                            <div className="progressBar" style={{width: `${filled}%`}}></div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="loadingEnterFade"></div>
            <div className="loadingExitFade"></div>
        </div>  
    );
}

export default Loading;